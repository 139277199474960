import Vue from 'vue';
import Vuex from 'vuex';

import cart from './cart/store';

Vue.use(Vuex);

export default new Vuex.Store({
    namespaced: true,
    strict: process.env.NODE_ENV !== 'production',

    state: {
        ready: false,
        gMapsIsLoaded: false,
        entrypointIntegrityHashes: {
            public: null,
        },
    },

    getters: {
    },

    actions: {
        setIntegrityHash ({ commit, state }, { entrypoint, hash }) {
            if (state.entrypointIntegrityHashes[entrypoint]) {
                // eslint-disable-next-line no-console
                console.error('Integrity hash already set for '+entrypoint+' entry point. Won\'t update.');
                return;
            }

            commit('setIntegrityHash', { entrypoint, hash });
        },
    },

    mutations: {
        ready (state) {
            state.ready = true;
        },

        gMapsIsLoaded (state) {
            state.gMapsIsLoaded = true;
        },

        setIntegrityHash (state, { entrypoint, hash }) {
            state.entrypointIntegrityHashes[entrypoint] = hash;
        },
    },

    modules: {
        cart,
    },
});
