var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showDevWarning
      ? _c("div", { staticClass: "dev_warning" }, [
          _c("span", { staticClass: "font-bold" }, [
            _vm._v("Development & training site"),
          ]),
          _vm._v("\n        – use for testing only\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "page-container" },
      [
        _c("div", { staticClass: "content-wrap" }, [
          _c(
            "header",
            { staticClass: "mb-6 text-center" },
            [
              _c(
                "div",
                { staticClass: "sm:flex justify-between items-center" },
                [
                  _c("router-link", { attrs: { to: { name: "start" } } }, [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: "/images/logo-full.svg",
                        width: "150",
                        height: "37",
                        alt: "FLC Seniors",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex justify-center sm:justify-start mt-6 sm:mt-0",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "block text-right",
                          attrs: { href: "https://www.flcseniors.ca/" },
                        },
                        [_vm._v("FLC Main Site")]
                      ),
                      _vm._v(" "),
                      _c("cart-summary", { attrs: { icon: "cart" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("public-nav"),
            ],
            1
          ),
          _vm._v(" "),
          _c("main", { staticClass: "h-full" }, [_c("router-view")], 1),
        ]),
        _vm._v(" "),
        _c(
          "footer",
          {
            staticClass:
              "absolute bottom-0 left-0 right-0 print:hidden mt-16 py-4 bg-gray-800 text-gray-300",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "sm:flex justify-between items-center max-w-11/12 xl:max-w-6xl mx-auto text-center sm:text-left",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "block text-gray-300 hover:text-white",
                    attrs: { href: "https://www.flcseniors.ca/" },
                  },
                  [_vm._v("FLC Seniors Home")]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "text-xs text-gray-500" }, [
                  _vm._v(
                    "\n                    ©" +
                      _vm._s(_vm.copyrightYear) +
                      " FLC Seniors Club All Rights Reserved\n                "
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("portal-target", { attrs: { name: "modal", multiple: "" } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }