var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    { staticClass: "text-sm leading-6" },
    [
      _vm._v("\n    Can't remember your number?\n    "),
      _c("router-link", { attrs: { to: { name: "member-search" } } }, [
        _vm._v("Find your membership number"),
      ]),
      _c("br"),
      _vm._v("\n    Not an FLC member yet?\n    "),
      _c("router-link", { attrs: { to: { name: "signup" } } }, [
        _vm._v("Register now"),
      ]),
      _c("br"),
      _vm._v(
        "\n    For other FLC membership questions, please contact membership coordinator at\n    "
      ),
      _c("a", { attrs: { href: "mailto:membership@flcseniors.ca" } }, [
        _vm._v("membership@flcseniors.ca"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }