var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasErrors
    ? _c(
        "ul",
        {
          staticClass: "field-errors",
          attrs: { role: "alert", "aria-live": "polite" },
        },
        _vm._l(_vm.flatErrors, function (error, i) {
          return _c("li", { key: i }, [_vm._v(_vm._s(error))])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }