import lowerCase from 'lodash/lowerCase';
import upperFirst from 'lodash/upperFirst';
import { formatNumber as libFormatPhone } from 'libphonenumber-js';
import Flatpickr from 'flatpickr';
import pluralizeFunction from 'pluralize';

export const formatPhone = function (phone, format = 'NATIONAL') {
    if (!phone) {
        return;
    }

    let str = phone.phoneNumber;
    if (phone.extension) {
        str += ' x'+phone.extension;
    }

    return libFormatPhone(str, 'CA', format);
};

export const gender = function (gender) {
    switch (gender) {
        case 'FEMALE' :
            return 'Female';
        case 'MALE' :
            return 'Male';
    }

    return 'Unknown';
};

export const walkingCart = function (value) {
    switch (value) {
        case 'WALKING' :
            return 'Walking';
        case 'CART' :
            return 'Cart';
    }

    return 'Unknown';
};

export const paymentType = function (value) {
    switch (value) {
        case 'IMMEDIATE' :
            return 'Immediate';
        case 'DELAYED' :
            return 'Delayed';
    }

    return 'Unknown';
};

export const date = function (date, format = 'M j, Y') {
    if (null === date) {
        return null;
    }

    return Flatpickr.formatDate(Flatpickr.parseDate(date, 'Y-m-d'), format);
};

export const money = function (value, decimals = 2) {
    const price = parseFloat(value / 100);

    // from what I'm reading, Android doesn't fully support the locales and options
    try {
        return price.toLocaleString('en-CA', {
            style: 'currency',
            currency: 'CAD',
            maximumFractionDigits: decimals,
        });
    } catch (e) {
        return '$'+price.toFixed(decimals);
    }
};

export const pluralize = function (string, count) {
    return pluralizeFunction(string, count);
};

export const singular = function (string) {
    return pluralizeFunction.singular(string);
};

export const itemProductName = function (item) {
    let name = '';

    if (item.product.productType === 'ACTIVITY') {
        name = name+'Activity: ';
    }

    return name+item.name;
};

export const eventType = function (type, count) {
    return pluralizeFunction('EVENT_TYPE' === type ? 'event' : 'class', count || 1);
};

export const upperFirstFilter = function (string) {
    return upperFirst(lowerCase(string));
};

export const truncate = function (string, length) {
    if (!string || string.length < length) {
        return string;
    }

    return string.substring(0, length)+'…';
};
