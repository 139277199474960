var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "router-link",
        {
          staticClass:
            "flex items-center ml-6 text-right text-blue-500 font-bold",
          staticStyle: { top: "2rem", right: "2rem" },
          attrs: { to: { name: "cart" } },
        },
        [
          _c("public-icon", {
            staticClass: "w-5 h-5 mr-2 mb-0 fill-current",
            attrs: { icon: _vm.icon },
          }),
          _vm._v("\n    Cart\n"),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }