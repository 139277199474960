<template>
    <div>
        <div v-if="showDevWarning" class="dev_warning">
            <span class="font-bold">Development &amp; training site</span>
            – use for testing only
        </div>

        <div class="page-container">
            <div class="content-wrap">
                <header class="mb-6 text-center">
                    <div class="sm:flex justify-between items-center">
                        <router-link :to="{ name: 'start' }">
                            <img src="/images/logo-full.svg"
                                 width="150"
                                 height="37"
                                 class="mx-auto"
                                 alt="FLC Seniors">
                        </router-link>
                        <div class="flex justify-center sm:justify-start mt-6 sm:mt-0">
                            <a href="https://www.flcseniors.ca/" class="block text-right">FLC Main Site</a>
                            <cart-summary icon="cart" />
                        </div>
                    </div>
                    <public-nav />
                </header>
                <main class="h-full">
                    <!-- *** where the router component is placed *** -->
                    <router-view />
                </main>
            </div>
            <footer class="absolute bottom-0 left-0 right-0 print:hidden mt-16 py-4 bg-gray-800 text-gray-300">
                <div class="sm:flex justify-between items-center max-w-11/12 xl:max-w-6xl
                            mx-auto text-center sm:text-left">
                    <a href="https://www.flcseniors.ca/"
                       class="block text-gray-300 hover:text-white">FLC Seniors Home</a>
                    <span class="text-xs text-gray-500">
                        ©{{ copyrightYear }} FLC Seniors Club All Rights Reserved
                    </span>
                </div>
            </footer>

            <portal-target name="modal" multiple />
        </div>
    </div>
</template>

<script>
import cartSummary from './cart/summary';

export default {
    metaInfo: {
        titleTemplate: '%s | FLC Seniors',
    },

    components: {
        cartSummary,
    },

    data () {
        return {
            copyrightYear: (new Date()).getFullYear(),
        };
    },

    computed: {
        showDevWarning () {
            return 'dbdev.flcseniors.ca' === window.location.hostname;
        },
    },
};
</script>
