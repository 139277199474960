var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", [
    _c("use", { attrs: { "xlink:href": _vm.iconsPath + "#" + _vm.icon } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }