<template>
    <router-link v-if="show"
                 :to="{ name: 'cart' }"
                 class="flex items-center ml-6 text-right text-blue-500 font-bold"
                 style="top: 2rem; right: 2rem;">
        <public-icon :icon="icon" class="w-5 h-5 mr-2 mb-0 fill-current" />
        Cart
    </router-link>
</template>

<script>
import { mapState } from 'vuex';
import { ProductsQuery } from '@/queries/product.query.graphql';

export default {
    props: {
        icon: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapState('cart', [
            'items',
        ]),

        show () {
            if (!this.$store.state.cart.cartId) {
                return false;
            }

            if (this.items.length === 0) {
                return false;
            }

            return !(this.$route.name === 'cart' || this.$route.name === 'cart-completed')
        },
    },

    apollo: {
        products: {
            query: ProductsQuery,
            update ({ Products }) {
                this.$store.dispatch('cart/storeProducts', Products);

                return Products;
            },
        },
    },

    async mounted () {
        await this.$store.dispatch('cart/loadCartIdFromCookie');
        if (this.$store.state.cart.cartId) {
            this.$store.dispatch('cart/loadCart');
        }
    },
};
</script>
