var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex justify-center items-center" },
    [
      _c("div", {
        staticClass: "loading",
        class: _vm.spinnerClasses,
        attrs: { "aria-hidden": "true" },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }