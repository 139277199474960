import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import { scrollBehavior, parseQuery, stringifyQuery, logPageView } from '@/common/router_helpers';

import { RouteQuery } from './queries/route.query.graphql';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: '/public',

    routes: [
        {
            name: 'start',
            path: '',
            component: () => import(/* webpackChunkName: "signup" */ './start'),
        },
        {
            name: 'renew',
            path: '/renew',
            component: () => import(/* webpackChunkName: "signup" */ './renew'),
        },
        {
            name: 'signup',
            path: '/signup',
            component: () => import(/* webpackChunkName: "signup" */ './signup'),
        },
        {
            name: 'activity',
            path: '/activity',
            component: () => import(/* webpackChunkName: "signup" */ './activity'),
        },
        {
            name: 'events',
            path: '/events',
            component: () => import(/* webpackChunkName: "events" */ './event'),
        },
        {
            name: 'events-activity',
            path: '/events/:activityId',
            component: () => import(/* webpackChunkName: "events" */ './event/activity'),
            props: true,
        },
        {
            // this route is also in the admin
            name: 'event',
            path: '/event/:eventId',
            component: () => import(/* webpackChunkName: "events" */ './event/view'),
            props: true,
        },
        {
            // this route is also in the admin
            name: 'event-schedule',
            path: '/event/:eventId/schedule',
            component: () => import(/* webpackChunkName: "events" */ './event/schedule'),
            props: true,
        },
        {
            name: 'member-search',
            path: '/member-search',
            component: () => import(/* webpackChunkName: "signup" */ './member_search'),
        },
        {
            // this path is also in the PHP UrlGenerator
            name: 'cart-completed',
            path: '/cart/completed/:cartId',
            component: () => import(/* webpackChunkName: "signup" */ './cart/completed'),
            props: true,
        },
        {
            // this path is also in the PHP UrlGenerator
            name: 'cart-review',
            path: '/cart/review/:cartId',
            component: () => import(/* webpackChunkName: "signup" */ './cart/review'),
            props: true,
        },
        {
            // this path is also in the PHP UrlGenerator
            name: 'cart-event-payment',
            path: '/cart/event-payment/:eventId/:participantId/:membershipNumber',
            component: () => import(/* webpackChunkName: "signup" */ './cart/event_payment'),
            props: true,
        },
        {
            name: 'cart',
            path: '/cart',
            component: () => import(/* webpackChunkName: "signup" */ './cart'),
        },
        {
            name: 'subscribe',
            path: '/subscribe',
            component: () => import(/* webpackChunkName: "signup" */ './subscribe'),
        },

        {
            path: '*',
            name: '404',
            component: () => import(/* webpackChunkName: "public-404" */ './error/404'),
        },
    ],

    scrollBehavior,
    parseQuery,
    stringifyQuery,
});

router.beforeEach( async (to, from, next) => {
    const waitForApollo = function () {
        return new Promise((resolve) => {
            (function waitingForApollo (){
                if (router.app.$apolloProvider) {
                    return resolve();
                }

                setTimeout(waitingForApollo, 10);
            })();
        });
    };

    // check if Apollo is setup on the Vue instance
    await waitForApollo();

    // check to see if they're still authenticated
    const result = await router.app.$apollo.query({
        query: RouteQuery,
    });
    // JS files have changed
    if (result.data.EntrypointIntegrity !== store.state.entrypointIntegrityHashes.public) {
        if (result.data.EntrypointIntegrity && store.state.entrypointIntegrityHashes.public) {
            window.location.reload();

            return;
        }
    }

    next();
});

router.afterEach((to) => {
    logPageView(to);
});

export default router;
