var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    {
      staticClass: "field-errors mb-4",
      attrs: { role: "alert", "aria-live": "polite" },
    },
    [
      _c(
        "li",
        [
          _vm._t("default", function () {
            return [_vm._v("Please fix the errors below and try again.")]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }