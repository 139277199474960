import Vue from 'vue';
import PortalVue from 'portal-vue';
import VueModal from 'vue-js-modal';
import VueMeta from 'vue-meta';
import Vuelidate from 'vuelidate';
import AsyncComputed from 'vue-async-computed';

import * as Sentry from '@sentry/vue';

import router from '@/public/router';
import store from '@/public/store';
import apolloProvider from './common/apollo';
import * as filters from './common/filters';

import app from './public/index';

import loadingSpinner from './common/loading_spinner';
import publicNav from './common/public_nav';
import formError from './common/form_error';
import fieldErrors from './common/field_errors';
import fieldError from './common/field_error';
import publicIcon from './common/public_icon';
import membershipInfo from './common/membership_info';

// SASS/CSS
import '../../css/public.scss';

// disable the warning about dev/prod
Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dns: process.env.SENTRY_DSN,
    });
}

Vue.use(PortalVue);
Vue.use(VueModal, { componentName: 'vue-modal' });
Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(AsyncComputed);

Vue.component('loading-spinner', loadingSpinner);
Vue.component('public-nav', publicNav);
Vue.component('form-error', formError);
Vue.component('field-errors', fieldErrors);
Vue.component('field-error', fieldError);
Vue.component('public-icon', publicIcon);
Vue.component('membership-info', membershipInfo);
Vue.component('public-modal', () => import(/* webpackChunkName: "public-modal" */ './common/modal'));
Vue.component('local-time', () => import(/* webpackChunkName: "local-time" */ './common/local_time'));

Vue.filter('money', filters.money);
Vue.filter('date', filters.date);
Vue.filter('singular', filters.singular);
Vue.filter('eventType', filters.eventType);
Vue.filter('upperFirst', filters.upperFirstFilter);
Vue.filter('walkingCart', filters.walkingCart);

(async () => {
    const entrypointScript = document.querySelector('script[data-entry="public"]');
    if (entrypointScript && entrypointScript.integrity) {
        await store.dispatch('setIntegrityHash', {
            entrypoint: 'public',
            hash: entrypointScript.integrity,
        });
    }

    store.commit('ready');

    window.App = new Vue({
        el: '#app',
        router,
        store,
        apolloProvider,

        render: h => h(app),

        metaInfo: {
            title: 'FLC Seniors',
        },
    });
})();
