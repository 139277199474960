var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasErrors
    ? _c(
        "ul",
        {
          staticClass: "field-errors",
          attrs: { role: "alert", "aria-live": "polite" },
        },
        [
          _c(
            "li",
            [
              _vm._t("default", function () {
                return [
                  !_vm.required
                    ? [
                        _vm._t("required", function () {
                          return [_vm._v("Required")]
                        }),
                      ]
                    : !_vm.minLength
                    ? [
                        _vm._t(
                          "minLength",
                          function () {
                            return [
                              _vm._v(
                                "\n                    Must be at least " +
                                  _vm._s(_vm.v.$params.minLength.min) +
                                  "\n                    " +
                                  _vm._s(
                                    _vm._f("pluralize")(
                                      "character",
                                      _vm.v.$params.minLength.min
                                    )
                                  ) +
                                  ".\n                "
                              ),
                            ]
                          },
                          { v: _vm.v }
                        ),
                      ]
                    : !_vm.maxLength
                    ? [
                        _vm._t(
                          "maxLength",
                          function () {
                            return [
                              _vm._v(
                                "\n                    Cannot be more than " +
                                  _vm._s(_vm.v.$params.maxLength.max) +
                                  "\n                    " +
                                  _vm._s(
                                    _vm._f("pluralize")(
                                      "character",
                                      _vm.v.$params.maxLength.max
                                    )
                                  ) +
                                  ".\n                "
                              ),
                            ]
                          },
                          { v: _vm.v }
                        ),
                      ]
                    : !_vm.between
                    ? [
                        _vm._t(
                          "between",
                          function () {
                            return [
                              _vm._v(
                                "\n                    Must be between " +
                                  _vm._s(_vm.v.$params.between.min) +
                                  "\n                    and " +
                                  _vm._s(_vm.v.$params.between.max) +
                                  ".\n                "
                              ),
                            ]
                          },
                          { v: _vm.v }
                        ),
                      ]
                    : !_vm.minValue
                    ? [
                        _vm._t(
                          "minValue",
                          function () {
                            return [
                              _vm._v(
                                "\n                    Must be " +
                                  _vm._s(_vm.v.$params.minValue.min) +
                                  " or more.\n                "
                              ),
                            ]
                          },
                          { v: _vm.v }
                        ),
                      ]
                    : !_vm.maxValue
                    ? [
                        _vm._t(
                          "maxValue",
                          function () {
                            return [
                              _vm._v(
                                "\n                    Must be less than or equal to " +
                                  _vm._s(_vm.v.$params.maxValue.max) +
                                  ".\n                "
                              ),
                            ]
                          },
                          { v: _vm.v }
                        ),
                      ]
                    : !_vm.url
                    ? [
                        _vm._t(
                          "url",
                          function () {
                            return [
                              _vm._v(
                                "\n                    The URL is not valid.\n                "
                              ),
                            ]
                          },
                          { v: _vm.v }
                        ),
                      ]
                    : !_vm.email
                    ? [
                        _vm._t(
                          "email",
                          function () {
                            return [
                              _vm._v(
                                "\n                    This email is invalid.\n                "
                              ),
                            ]
                          },
                          { v: _vm.v }
                        ),
                      ]
                    : !_vm.valid
                    ? [
                        _vm._t(
                          "valid",
                          function () {
                            return [
                              _vm._v(
                                "\n                    This is not a valid value.\n                "
                              ),
                            ]
                          },
                          { v: _vm.v }
                        ),
                      ]
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }