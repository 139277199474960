var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass:
        "print:hidden flex flex-col md:flex-row justify-center mt-6 mb-3 py-3 text-center bg-gray-200",
    },
    [
      _c(
        "router-link",
        { staticClass: "header-nav_item", attrs: { to: { name: "events" } } },
        [_vm._v("Events & Classes")]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "header-nav_item", attrs: { to: { name: "renew" } } },
        [_vm._v("Renew Membership")]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "header-nav_item", attrs: { to: { name: "signup" } } },
        [_vm._v("Sign Up")]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "header-nav_item", attrs: { to: { name: "activity" } } },
        [_vm._v("Sign Up for Activity")]
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "ring-offset-gray-200",
          attrs: { to: { name: "start" } },
        },
        [_vm._v("Home")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }