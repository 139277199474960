import { v4 as uuid4 } from 'uuid';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import has from 'lodash/has';
import pick from 'lodash/pick';
import Cookies from 'js-cookie';
import apolloProvider from '@/common/apollo';

import { CartQuery } from '../queries/cart.query.graphql';
import {
    CartCreateMutation,
    CartEmptyMutation,
    CartAddItemMutation,
    CartRemoveItemMutation,
} from '../queries/cart.mutation.graphql';

const apolloClient = apolloProvider.defaultClient;

const state = {
    stripeKey: process.env.STRIPE_PUBLISHABLE_KEY,
    cartLoaded: false,
    cartId: null,
    items: [],
    products: null,
};

const getters = {
    getProductById: (state) => (productId) => {
        return find(state.products, { productId });
    },
    getProductByType: (state) => (productType) => {
        return find(state.products, { productType });
    },
    getProductsByType: (state) => (productType) => {
        return filter(state.products, { productType });
    },
    hasActivityProduct (state, getters) {
        return getters.getProductsByType('ACTIVITY').length > 0;
    },
    hasEventProduct (state, getters) {
        return getters.getProductsByType('EVENT').length > 0;
    },

    hasRenewalForMemberId: (state) => (memberId) => {
        return state.items
            .filter((item) => item.product.productType === 'MEMBERSHIP_RENEWAL' && item.memberId === memberId)
            .length > 0;
    },
    hasActivitySignupForMemberId: (state) => (activityProductId, memberId) => {
        return state.items
            .filter((item) => item.product.productId === activityProductId && item.memberId === memberId)
            .length > 0;
    },
    hasParticipant: (state) => (participantId) => {
        return state.items
            .filter((item) => item.data && item.data.participantId && item.data.participantId === participantId)
            .length > 0;
    },

    total: (state, getters) => (items) => {
        return items.reduce(
            (total, item) => {
                if (item.price) {
                    return total + +item.price;
                }

                const product = getters.getProductById(item.product.productId) || { price: 0 };

                return total + +product.price;
            },
            0
        );
    },
    refundTotal: () => (refunds) => {
        return refunds.reduce((total, refund) => {
            return total + +refund.amount;
        }, 0);
    },
};

const actions = {
    storeProducts ({ commit }, products) {
        // remove any activity products that don't have a current activity year
        // they can't be purchased anyway
        const filteredProducts = products.filter((product) => {
            if (product.productType === 'ACTIVITY') {
                return !!product.activity.currentActivityYear;
            }

            return true;
        });

        commit('setProducts', filteredProducts);
    },

    loadCartIdFromCookie ({ commit }) {
        const cartId = Cookies.get('cart');

        if (cartId) {
            commit('setCartId', cartId);
        }
    },
    async createCart ({ commit }) {
        const cartId = uuid4();

        await apolloClient.mutate({
            mutation: CartCreateMutation,
            variables: {
                cartId,
            },
        });

        Cookies.set('cart', cartId);
        commit('setCartId', cartId);
        commit('cartIsLoaded');
    },
    async loadCart ({ state, commit }) {
        // so we start with a fresh "copy"
        commit('removeAllItems');
        commit('cartNotLoaded');

        const { data: { Cart: cart } } = await apolloClient.query({
            query: CartQuery,
            variables: {
                cartId: state.cartId,
            },
        });

        // cart not found OR status is not NEW
        // remove cookie & unset cartId
        // cart id may also have been removed be another process
        // while the query was running
        if (!cart || !state.cartId || cart.status !== 'NEW') {
            Cookies.remove('cart');
            commit('setCartId', null);

            return;
        }

        cart.items.forEach((item) => {
            commit('add', { ...item });
        });

        commit('cartIsLoaded');
    },
    emptyCart ({ state, dispatch }) {
        apolloClient.mutate({
            mutation: CartEmptyMutation,
            variables: {
                cartId: state.cartId,
            },
        });

        dispatch('clearCart');
    },
    clearCart ({ commit }) {
        commit('removeAllItems');
        Cookies.remove('cart');
        commit('setCartId', null);
    },

    async addItem ({ state, commit, dispatch }, { product, memberId, data }) {
        if (null === state.cartId) {
            await dispatch('createCart');
        }

        const cartItemId = uuid4();

        data = pick(data, [
            'membershipYearId',
            'activityYearId',
            'eventId',
            'participantId',
            'partnerMemberId',
            'membershipNumber',
            'firstName',
            'lastName',
            'lastInitial',
            'address',
            'email',
            'subscribeToMailingList',
            'phoneNumber',
            'cellPhone',
            'birthYear',
            'gender',
        ]);

        if (has(data, 'membershipNumber')) {
            data.membershipNumber = +data.membershipNumber;
        }
        if (has(data, 'birthYear')) {
            if (data.birthYear > 0) {
                data.birthYear = +data.birthYear;
            } else {
                data.birthYear = null;
            }
        }

        const { data: { CartAddItem } } = await apolloClient.mutate({
            mutation: CartAddItemMutation,
            variables: {
                cartId: state.cartId,
                item: {
                    cartItemId,
                    productId: product.productId,
                    memberId,
                    data,
                },
            },
        });

        commit('add', {
            cartItemId: CartAddItem.cartItemId,
            product: CartAddItem.product,
            memberId: CartAddItem.memberId,
            price: CartAddItem.price,
            data: CartAddItem.data,
        });
    },
    removeItem ({ commit }, cartItemId) {
        // we'll just continue and hope this doesn't fail
        apolloClient.mutate({
            mutation: CartRemoveItemMutation,
            variables: {
                cartId: state.cartId,
                cartItemId,
            },
        });

        commit('remove', cartItemId);
    },
};

const mutations = {
    setProducts (state, products) {
        state.products = products;
    },

    cartIsLoaded (state) {
        state.cartLoaded = true;
    },
    cartNotLoaded (state) {
        state.cartLoaded = false;
    },

    setCartId (state, cartId) {
        state.cartId = cartId;
    },

    add (state, item) {
        state.items.push(item);
    },
    remove (state, cartItemId) {
        state.items.splice(findIndex(state.items, { cartItemId }), 1);
    },
    removeAllItems (state) {
        state.items = [];
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
